import React from 'react';
import PropTypes from 'prop-types';
import ResponsiveImage from 'bbc-morph-responsive-image-srcset';
import iChefTransformer from 'bbc-morph-image-chef-url-transformer';
import classNames from 'bbc-morph-classnames';
import typographyInfo from 'bbc-morph-ws-typography-info';
import MorphRequire from 'morph-require';

export default class PromoImage extends React.Component {
  componentWillMount() {
    const { serviceName } = this.props;
    const cssDirection = typographyInfo.getDirectionality(serviceName);

    MorphRequire.selfTransitiveThenUpdate(cssDirection.toLowerCase(), this);
  }

  imageProps(imageClassName, imageFile) {
    const {
      chefSizes,
      ichefRecipe,
      product,
      ichefExcludeType,
      alt,
      width,
      sizes,
      delayed,
    } = this.props;

    if (ichefRecipe || imageFile) {
      return {
        src: imageFile || iChefTransformer.transform(ichefRecipe, product, ichefExcludeType),
        alt,
        width,
        chefSizes,
        sizes,
        className: classNames(imageClassName),
        delayed,
      };
    }

    return {};
  }

  render() {
    const {
      className,
      image,
      imgClassName,
      imageOnly,
      imageSibling,
      children,
      responsiveImageRatio,
      responsiveImageClasses,
    } = this.props;
    const imageProps = this.imageProps(imgClassName, image);
    const responsiveImage = <ResponsiveImage {...imageProps} />;

    if (imageOnly) {
      return responsiveImage;
    }

    const wrapperClassNames = classNames('gs-c-promo-image', className);
    const responsiveImageClassNames = classNames(
      'gs-o-responsive-image',
      responsiveImageRatio,
      responsiveImageClasses,
    );

    return (
      <div className={wrapperClassNames}>
        <div className="gs-o-media-island">
          <div className={responsiveImageClassNames}>
            {responsiveImage}
            {imageSibling}
          </div>
          {children}
        </div>
      </div>
    );
  }
}

PromoImage.displayName = 'PromoImage';

PromoImage.propTypes = {
  alt: PropTypes.string,
  chefSizes: PropTypes.arrayOf(PropTypes.number),
  children: PropTypes.node,
  className: PropTypes.arrayOf(PropTypes.string),
  delayed: PropTypes.oneOfType([React.PropTypes.bool, React.PropTypes.string]),
  ichefRecipe: PropTypes.string,
  image: PropTypes.string,
  imgClassName: PropTypes.oneOfType([
    React.PropTypes.arrayOf(React.PropTypes.string),
    React.PropTypes.string,
  ]),
  imageOnly: PropTypes.bool,
  product: PropTypes.string,
  responsiveImageRatio: PropTypes.string,
  responsiveImageClasses: PropTypes.string,
  imageSibling: PropTypes.node,
  sizes: PropTypes.string,
  ichefExcludeType: PropTypes.bool,
  width: PropTypes.number,
  serviceName: PropTypes.string,
};

PromoImage.defaultProps = {
  alt: '',
  chefSizes: [240, 320, 480, 624, 800],
  children: null,
  className: [],
  delayed: false,
  ichefRecipe: null,
  image: null,
  imgClassName: [],
  product: null,
  responsiveImageClasses: null,
  imageSibling: null,
  sizes: null,
  width: null,
  serviceName: null,
  ichefExcludeType: false,
  imageOnly: false,
  responsiveImageRatio: 'gs-o-responsive-image--16by9',
};
